
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { Action, Getter, Mutation } from 'vuex-class';
    import ScenarioStatus from '@/components/main/ScenarioStatus.vue';

    const namespace = 'main';

    @Component({
        components: {
            ScenarioStatus
        },
    })
    export default class FloatingMenu extends Vue {
        @Prop({ default: null }) public legend: any;
        @Getter('getLocationDrivers', { namespace }) getLocationDrivers: any;
        @Getter('getModel', { namespace }) getModel: any;
        @Getter('getCatchmentArea', { namespace }) getCatchmentArea: any;
        @Mutation('SET_CATCHMENTAREA', { namespace }) setCatchmentArea: any;
        @Getter('getIsoLineParameters', { namespace }) getIsoLineParameters: any;
        @Getter('getSubModel', { namespace }) getSubModel: any;
        @Getter('getScenario', { namespace }) getScenario: any;
        @Getter('getScenarios', { namespace }) getScenarios: any;
        @Getter('getLocation', { namespace }) getLocation: any;
        @Getter('getLocations', { namespace }) getLocations: any;
        @Getter('getLocationsNotExcluded', { namespace }) getLocationsNotExcluded: any;
         @Getter('getFilteredLocations', { namespace }) getFilteredLocations: any;
        @Getter('getArea', { namespace }) getArea: any;
          @Getter('getLocationCatchmentArea', { namespace }) getLocationCatchmentArea: any;
         @Getter('getReadOnly', { namespace }) getReadOnly: any;
        @Getter('getStandByFilterLocations', { namespace }) getStandByFilterLocations: any;
        @Action('searchByBoundingBox', { namespace }) search: any;
        @Action('filterLocations', { namespace }) filterLocations: any;
        @Getter('getScenarioLocationsVisible', { namespace }) getScenarioLocationsVisible: any;
        @Getter('getScenarioLocations', { namespace }) getScenarioLocations: any;
        @Mutation('SET_SCENARIOLOCATIONSVISIBLE', { namespace }) setScenarioLocationsVisible: any;
        @Getter('getFileFormatCulture') getFileFormatCulture!: any;
        @Getter('getAreaSetsVisible', { namespace }) getAreaSetsVisible: any;
        @Getter('getFilterLocations', { namespace }) getFilterLocations: any;
        @Getter('getPoiSetsVisible', { namespace }) getPoiSetsVisible: any;
        @Getter('getHeatMapSetsVisible', { namespace }) getHeatMapSetsVisible: any;
        @Getter('getIsoLineSet', { namespace }) getIsoLineSet: any;
        @Mutation('SET_ISOLINESET', { namespace }) setIsoLineSet: any;
        @Getter('getIsoLineMinutes', { namespace }) getIsoLineMinutes: any;
        @Mutation('SET_ISOLINEMINUTES', { namespace }) setIsoLineMinutes: any;
        @Action('fetchLocationIsoLines', { namespace }) fetchLocationIsoLines: any;


        @Mutation('SET_FILEFORMATCULTURE') setFileFormatCulture: any;
        @Action('downloadCsv') downloadCsv: any;
        @Action('downloadFile') downloadFile: any;
        @Action('setLocation', { namespace }) setLocation: any;
        @Action('undoCloseLocation', { namespace }) undoCloseLocation: any;
        @Action('undoEditLocation', { namespace }) undoEditLocation: any;
        @Action('removeLocation', { namespace }) removeLocation: any;

        @Mutation('SET_AREASETSVISIBLE', { namespace }) setAreaSetsVisible: any;
        
        @Mutation('SET_STANDBYFILTERLOCATIONS', { namespace }) setStandByFilterLocations: any;

        @Mutation('SET_POISETSVISIBLE', { namespace }) setPoiSetsVisible: any;
        @Mutation('SET_HEATMAPSETSVISIBLE', { namespace }) setHeatMapSetsVisible: any;
        @Action('fetchPois', { namespace }) fetchPois: any;
        @Action('fetchHeatMapPoints', { namespace }) fetchHeatMapPoints: any;
        @Action('fetchCatchmentArea', { namespace }) fetchCatchmentArea: any;
        

        @Getter('getMatchingLocations', { namespace }) getMatchingLocations: any;
        @Action('calculateScenario', { namespace }) calculateScenario: any;
        @Action('editScenario', { namespace }) editScenario: any;
        @Action('mergeScenario', { namespace }) mergeScenario: any;
        @Action('removeScenario', { namespace }) removeScenario: any;
        @Action('fetchScenarioProcessingLog', { namespace }) fetchScenarioProcessingLog: any;
        @Action('fetchIsoLine', { namespace }) fetchIsoLine: any;
       


        private pageNumberFilteredLocations: any = 0;


        get paginatedFilteredLocations() {
            return this.getFilteredLocations.slice(this.pageNumberFilteredLocations * 10, this.pageNumberFilteredLocations * 10 + 10);
        }

        private setFilterLocations(value: any) {
            this.pageNumberFilteredLocations = 0;
            this.filterLocations(value);
        }

        get standByFilterLocations(): any {
            return this.getStandByFilterLocations;
        }
        set standByFilterLocations(value: any) {
            this.setStandByFilterLocations(value);
        }

        
        get catchmentArea(): any {
            return this.getCatchmentArea;
        }
        set catchmentArea(value: any) {
            this.fetchCatchmentArea();
            this.setCatchmentArea(value);
        }

        get isoLineSet(): any {
            return this.getIsoLineSet;
        }
        set isoLineSet(value: any) {
            this.setIsoLineSet(value);
            this.fetchLocationIsoLines();
        }

        get isoLineMinutes(): any {
            return this.getIsoLineMinutes;
        }
        set isoLineMinutes(value: any) {
            this.setIsoLineMinutes(value);
            this.fetchLocationIsoLines();
        }

        get scenarioLocationsVisible(): any {
            return this.getScenarioLocationsVisible;
        }
        set scenarioLocationsVisible(value: any) {
            this.setScenarioLocationsVisible(value);
        }

        get pageCountFilteredLocations(): any {
            return Math.ceil(this.getFilteredLocations.length / 10);
        }

        get allowNextPageFilteredLocations(): any {
            return (this.pageNumberFilteredLocations * 10 + 10 < this.getFilteredLocations.length);
        }
        get allowPreviousPageFilteredLocations(): any {
            return (this.pageNumberFilteredLocations > 0);
        }

        private nextPageFilteredLocations() {
            if (this.allowNextPageFilteredLocations) this.pageNumberFilteredLocations++;
        }

        private previousPageFilteredLocations() {
            if (this.allowPreviousPageFilteredLocations) this.pageNumberFilteredLocations--;
        }

       
        get areaSetsVisible(): any {
            return this.getAreaSetsVisible;
        }
        set areaSetsVisible(value: any) {
            this.setAreaSetsVisible(value);
        }
        get poiSetsVisible(): any {
            return this.getPoiSetsVisible;
        }
        set poiSetsVisible(value: any) {

            this.setPoiSetsVisible(value);
            this.fetchPois();
        }
        get heatMapSetsVisible(): any {
            return this.getHeatMapSetsVisible;

        }
        set heatMapSetsVisible(value: any) {
            this.setHeatMapSetsVisible(value);
            this.fetchHeatMapPoints();
        }

       
        
        get fileFormatCulture(): any {
            return this.getFileFormatCulture;
        }
        set fileFormatCulture(value: any) {
            this.setFileFormatCulture(value);
        }
        get noWhiteSpotAreaSets() {
            const _filteredAreaSets = this.getModel.areaSets.filter(function (areaSet: any) {
                return areaSet.isWhiteSpot == 0;
            });
            return _filteredAreaSets;
        }
        
        private searchLocal: any = '';

        get testUrl() {
            return '/api/models/scenarios/' + this.getScenario.id + '/locations/' + this.getLocation.id + '/calculation-model';
        }

        get changedLocations() {
            if (this.getLocations != null) {
                return this.getLocations.filter(function (location: any) {
                    return location.changeType != 0;
                });
            }
            else {
                return [];
            }
        }

        get languages() {
            return [
                { key: 'nl', text: this.$t('message.dutchFormat'), helpUrl: 'https://thebigdatacompany.sharepoint.com/:b:/s/BrancheOmzetModel/Eeg_BD_zaH1ArWdD59Z7awwBnLIgxFJtZ8wyAeDWzC-zHQ?e=4PQV0g' },
                { key: 'en', text: this.$t('message.englishFormat'), helpUrl: 'https://thebigdatacompany.sharepoint.com/:b:/s/BrancheOmzetModel/EQvrt3v6utZFsXKDDoMdbkkBpuSD3R13bVYzZduQIl2xdQ?e=tIkHF2' }
            ]
        }

        get scenarioHasChildren() {
            var self = this;
            var found = false;
            this.getScenarios.forEach(function (childScenario: any) {
                if (childScenario.parentScenarioId == self.getScenario.id) found = true;
            });
            return found;
        }

        get topScenarios() {
            var _self = this;
            return this.getScenarios.filter(function (scenario: any) {
                if (scenario.isTop && _self.getScenario.id != scenario.id) return true;
            });

        }


        get poiSetVisible(): any {
            if (this.poiSetsVisible.length == 0) return 0; else return this.poiSetsVisible[0];
        }
        set poiSetVisible(value: any) {
            if (value == null) this.poiSetsVisible = [0]; else this.poiSetsVisible = [value];
        }

        get areaSetVisible(): any {
            if (this.areaSetsVisible.length == 0) return 0; else return this.areaSetsVisible[0];
        }
        set areaSetVisible(value: any) {
            if (value == null) this.areaSetsVisible = [0]; else this.areaSetsVisible = [value];
        }

        get heatMapSetVisible(): any {
            if (this.heatMapSetsVisible.length == 0) return 0; else return this.heatMapSetsVisible[0];
        }
        set heatMapSetVisible(value: any) {
            if (value == null) this.heatMapSetsVisible = [0]; else this.heatMapSetsVisible = [value];
        }

        private async removeLocationLocal(location: any) {
            const res = await this.$confirm(this.$t('message.confirmRemoveLocation') + '', { buttonTrueText: String(this.$t('message.yes')), buttonFalseText: String(this.$t('message.no')), title: String(this.$t('message.warning')) });
            if (res) this.removeLocation(location);
        }
        private async undoCloseLocationLocal(location: any) {
            const res = await this.$confirm(this.$t('confirmUndoCloseLocation') + '', { buttonTrueText: String(this.$t('message.yes')), buttonFalseText: String(this.$t('message.no')), title: String(this.$t('message.warning')) });
            if (res) this.undoCloseLocation(location);
        }
        private async undoEditLocationLocal(location: any) {
            const res = await this.$confirm(this.$t('confirmUndoEditLocation') + '', { buttonTrueText: String(this.$t('message.yes')), buttonFalseText: String(this.$t('message.no')), title: String(this.$t('message.warning')) });
            if (res) this.undoEditLocation(location);
        }
        private async mergeScenarioLocal() {
            if (this.topScenarios.length == 0) 
            {
                await this.$confirm(this.$t('message.noTopScenariosFound') + '', { color: "error", buttonFalseText: null as any, buttonTrueText: String(this.$t('message.close')), title: String(this.$t('message.error')) });
            }
            else
            {
                this.mergeScenario({ scenarioId: this.getScenario.id, changedLocations: this.changedLocations,selectedLocations: [], deleteSourceScenario: false, destinationScenarioId: this.topScenarios[0].id  });
            }
        }

        private async showMatchingLocationsOutputChart() {
            this.$emit('showMatchingLocationsOutputChart');
        }
        private async showMatchingLocationsRadarChart() {

            this.$emit('showMatchingLocationsRadarChart');
        }
        private async showDriversChart() {
            this.$emit('showDriversChart');
        }
        private async showPropertiesChart() {
            this.$emit('showPropertiesChart');
        }
        private async showLocationReport() {
            this.$emit('showLocationReport');
        }
        private async showScenarioReport() {
            this.$emit('showScenarioReport');
        }
        private async showLocationScores() {
            this.$emit('showLocationScores');
        }
        private async showAreaScores() {
            this.$emit('showAreaScores');
        }
        private async showIsoLineHelp(isoLineParameter: any)
        {
           if (isoLineParameter.provider == 1) window.open('https://developer.here.com/documentation/isoline-routing-api/api-reference-swagger.html', '_blank');

           if (isoLineParameter.provider == 4) window.open('https://valhalla.github.io/valhalla/api/isochrone/api-reference/', '_blank');
        }

        private async confirmRemoveScenario() {
            const res = await this.$confirm(this.$t('message.confirmRemoveScenario') + '', { buttonTrueText: String(this.$t('message.yes')), buttonFalseText: String(this.$t('message.no')), title: String(this.$t('message.warning')) })
            if (res) this.removeScenario(this.getScenario);
        }

        private selectAllPoiSets() {

            let visible = [0];
            this.getModel.poiSets.forEach(function (poiSet: any) {
                visible.push(poiSet.id);
            });
            this.poiSetsVisible = visible;
        }

        private selectNoPoiSets() {
            this.poiSetsVisible = [0];
        }
    }
