
    declare const google: any;
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import MarkerClusterer from '@googlemaps/markerclustererplus';
    import { localeIncludes } from 'locale-includes';

    @Component
    export default class GoogleMap extends Vue {
        @Prop({ default: null }) public model: any;
        @Prop({ default: null }) public location: any;
        @Prop({ default: null }) public locations: any;
        @Prop({ default: null }) public scenarioLocations: any;
        @Prop({ default: 15 }) public zoom: any;
        @Prop({ default: false }) public showAreaSetID: any;
        @Prop({ default: null }) public areaSetsVisible: any;
        @Prop({ default: null }) public locationIsoLines: any;
        @Prop({ default: null }) public isoLineSet: any;
        @Prop({ default: null }) public isoLineMinutes: any;
        @Prop({ default: null }) public locationCatchmentArea: any;
        @Prop({ default: null }) public catchmentArea: any;
        
        
        @Prop({ default: null }) public area: any;
        @Prop({ default: null }) public refresh: any;
        @Prop({ default: 'greedy' }) public gestureHandling: any;
        @Prop({ default: false }) public mapTypeControl: any;
        @Prop({ default: false }) public streetViewControl: any;
        @Prop({ default: false }) public zoomControl: any;
        @Prop({ default: false }) public fullscreenControl: any;
        @Prop({ default: true }) public scaleControl: any;
        @Prop({ default: '' }) public filter: any;
        @Prop({ default: '' }) public searchResults: any;
        @Prop({ default: null }) public pois: any;
        @Prop({ default: null }) public heatMapPoints: any;
		@Prop({ default: null }) public scenarioLocationsVisible: any;
		@Prop({ default: null }) public onlinePois: any;
        
        private heatMapRadius = 1500;
        private map: any;
        
        private areasLayer: any;
        
        private clusterMaxZoom = 11;
        private clusterPoiMaxZoom = 11;
        private clusterGridSize = 64;

        private locationsCount = 0;
        private scenarioLocationsCount = 0;
        private poiCount = 0;


        private ZINDEXLOCATION = 30;
        private ZINDEXSCENARIOLOCATION = 40;
        private ZINDEXPOI = 20
        private ZINDEXSEARCHRESULT = 10

        
        private markersLocations: any;
        private markersScenarioLocations: any;
        private markersPois: any;
        private markersSearchResults: any;

        private markerClustererLocations: any;
        private markerClustererScenarioLocations: any;
        private markerClustererPois: any;

        private omsLocations: any;
        private omsScenarioLocations: any;

        private heatmaps: any = [];
        private locationIsoLinesFeatures: any;
        private locationCatchmentAreaFeatures: any;
        private circles: any;
        private areaLines: any;
        private areaFeatures: any;
        private legend: any = [];
        private touchTimer: any;
        private mouseoverTimeoutId = null;

        private getHeatmapRadius() {
            const metersPerPx = 156543.03392 * Math.cos(35.726332 * Math.PI / 180) / Math.pow(2, this.map.getZoom());
            return this.heatMapRadius / metersPerPx;
        }


        private mounted() {
            const _self = (this as any);
            const options = {
                zoom: _self.zoom,
                center: new google.maps.LatLng(52.3749, 4.897),
                scaleControl: this.scaleControl,
                gestureHandling: this.gestureHandling,
                mapTypeControl: this.mapTypeControl,
                mapTypeControlOptions: {
                    position: google.maps.ControlPosition.BOTTOM_CENTER
                },
                streetViewControl: this.streetViewControl,
                streetViewControlOptions: {
                    position: google.maps.ControlPosition.BOTTOM_CENTER
                },
                rotateControl: false,
                fullscreenControl: this.fullscreenControl,
                zoomControl: this.zoomControl,
            }
            // @ts-ignore
            _self.map = new google.maps.Map(document.getElementById('googleMap' + this._uid), options);
            _self.map.data.setStyle(function (feature: any) {
                return _self.getFeatureStyle(feature);
            });
            google.maps.event.addListener(_self.map, "click", function (event: any) {
                _self.$emit('leftClick', { x: event.pixel?.x, y: event.pixel?.y, latitude: event.latLng.lat(), longitude: event.latLng.lng() });
            });
            google.maps.event.addListener(_self.map, "bounds_changed", function () {
                // send the new bounds back to your server
                const bounds = _self.map.getBounds();
                const northEast = bounds.getNorthEast();
                const southWest = bounds.getSouthWest();
                _self.$emit('mapBoundsChanged', { west: southWest.lng(), south: southWest.lat(), east: northEast.lng(), north: northEast.lat() });
            });

            google.maps.event.addListener(_self.map, 'zoom_changed', function () {
                //_self.zoom = _self.map.getZoom();
                _self.heatmaps.forEach(async function (heatMap: any) {
                    heatMap.set("radius", _self.getHeatmapRadius());
                });
                
            });



            var infowindow = new google.maps.InfoWindow({
                pixelOffset: new google.maps.Size(0, -40) // offset for icon
            });
            _self.map.data.addListener('mouseover', function (event: any) {
                if (event.feature.getProperty('tooltip') && event.feature.getProperty('fraction') > 0) {
                    infowindow.setContent('' + event.feature.getProperty('tooltip') + '');
                    infowindow.setPosition(event.latLng);
                    infowindow.open(_self.map);
                }
                else {
                    infowindow.close();
                }
                _self.map.data.setStyle(function(feature : any) {
                    if (feature.getProperty('locationPropertyId') == event.feature.getProperty('locationPropertyId') && feature.getProperty('locationPropertyId') != null && event.feature.getProperty('fraction') > 0)
                    {
                        return _self.getFeatureStyle(feature,true);
                    }
                    else if (feature.getProperty('areaCode') == event.feature.getProperty('areaCode') && feature.getProperty('locationPropertyId') == null )
                    {
                        return _self.getFeatureStyle(feature, true);
                    }
                    else
                    {
                        return _self.getFeatureStyle(feature,false);
                    }
                });
            });
            _self.map.data.addListener('mouseout', function () {
                infowindow.close();
                _self.map.data.setStyle(function (feature: any) {
                    return _self.getFeatureStyle(feature);
                });
            });
            _self.map.data.addListener("click", function (event: any) {
                
                
                const overlay = new google.maps.OverlayView();
                overlay.setMap(_self.map);
                const point = overlay.getProjection().fromLatLngToContainerPixel(event.latLng)
                _self.$emit('leftClick', { x: point.x, y: point.y, latitude: event.latLng.lat(), longitude: event.latLng.lng() });
            });
            google.maps.event.addListener(_self.map, "rightclick", function (event: any) {
                _self.$emit('rightClick', { x: event.pixel.x, y: event.pixel.y, latitude: event.latLng.lat(), longitude: event.latLng.lng() });
            });
            _self.map.data.addListener("rightclick", function (event: any) {
                const overlay = new google.maps.OverlayView();
                overlay.setMap(_self.map);
                const point = overlay.getProjection().fromLatLngToContainerPixel(event.latLng)
                _self.$emit('rightClick', { x: point.x, y: point.y, latitude: event.latLng.lat(), longitude: event.latLng.lng() });
            });
            google.maps.event.addListener(_self.map, "mousedown", function (event: any) {
                if (!_self.touchTimer && event.domEvent.pointerType == "touch") {
                    _self.touchTimer = setTimeout(_self.onlongtouch, 1000, event);
                }
            });
            google.maps.event.addListener(_self.map, "mouseup", function () {
                
                if (_self.touchTimer) {
                    clearTimeout(_self.touchTimer);
                    _self.touchTimer = null;
                }
            });
            google.maps.event.addListener(_self.map, "click", function (event: any) {
                //Prevent point of interest on the map from being clicked else the popup can not be closed.
                if (event.placeId) event.stop();
            });

            //@ts-ignore
            _self.omsLocations = new OverlappingMarkerSpiderfier(_self.map, { markersWontMove: true, markersWontHide: true, legWeight: 2 });
            //@ts-ignore
            _self.omsScenarioLocations = new OverlappingMarkerSpiderfier(_self.map, { markersWontMove: true, markersWontHide: true, legWeight: 2 });


            _self.markerClustererPois = new MarkerClusterer(_self.map, [], {
                zIndex: this.ZINDEXPOI - 1,
                clusterClass: 'tbdc-markerClusterer',
                enableRetinaIcons: true,
                styles: [{
                    textColor: '#AAAAAA',
                    width: 40,
                    height: 40,
                    url: require('../../assets/clusterOfPois.png'),
                }]
            });

            _self.markerClustererLocations = new MarkerClusterer(_self.map, [], {
                zIndex: this.ZINDEXLOCATION - 1,
                clusterClass: 'tbdc-markerClusterer',
                enableRetinaIcons: true,
                styles: [{
                    textColor: '#333333',
                    width: 40,
                    height: 40,
                    url: require('../../assets/clusterOfLocations.png'),
                }]
            });

            _self.markerClustererScenarioLocations = new MarkerClusterer(_self.map, [], {
                zIndex: this.ZINDEXSCENARIOLOCATION - 1,
                clusterClass: 'tbdc-markerClusterer',
                enableRetinaIcons: true,
                styles: [{
                    textColor: '#009cbb',
                    width: 40,
                    height: 40,
                    url: require('../../assets/clusterOfScenarioLocations.png'),
                }]
            });

            this.onRefresh();
        }
        private getFeatureStyle(feature: any, highlight: any) {
            if (feature.getProperty('type') == 'c') { // catchment area
                return {
                    fillColor: highlight && feature.getProperty('tooltip') != null && feature.getProperty('fraction') > 0 ? 'DarkGreen' : feature.getProperty('color'),
                    fillOpacity: feature.getProperty('fraction') == 0 ? 0.1 : 0.8,
                    strokeOpacity: 1,
                    strokeWeight: feature.getProperty('fraction') == 0 ? 0.25 : (highlight && feature.getProperty('tooltip') != null ? 1.5 : 0.5),
                    strokeColor: 'black',
                    zIndex: highlight ? 2 : 1,
                }
            }
            else if (feature.getProperty('type') == 'i') { // isoline
                return {
                    fillOpacity: 0,
                    clickable: false,
                    strokeOpacity: 1,
                    strokeWeight: 5,
                    strokeColor: 'rgb(0, 96, 127)',
                    zIndex: 5,
                }
            } else if (feature.getProperty('type') == 'a') { // area
                return {
                    fillColor: 'darkgreen',
                    clickable: false,
                    fillOpacity: 0.8,
                    strokeOpacity: 1,
                    strokeWeight: 1,
                    strokeColor: 'black',
                    zIndex: 3,
                }
            } else {
                return null;
            }
        }
        private onlongtouch(event: any) {
            const _self = (this as any);
            _self.touchTimer = null;
            _self.$emit('rightClick', { x: event.pixel.x, y: event.pixel.y, latitude: event.latLng.lat(), longitude: event.latLng.lng() });
        }
        @Watch('refresh')
        private onRefresh() {
            const _self = (this as any);
            _self.$emit('startBusy');
            setTimeout(function () {
                if (_self.model) _self.onModelChanged(_self.locations, null);
                if (_self.locations) _self.onLocationsChanged(_self.locations, null);
                if (_self.locationCatchmentArea) _self.onLocationCatchmentAreaChanged();
                if (_self.location) {
                    _self.map.setCenter(_self.location);
                    _self.onLocationChanged(_self.location, null);
                }
                _self.$emit('refresh', _self.map);
                _self.$emit('endBusy');
            }, 100);
        }
        @Watch('model')
        private onModelChanged() {
            const _self = this;
            this.onLocationCatchmentAreaChanged();
            this.onLocationChanged(null, _self.location);
            this.onAreaSetsVisibleChanged();

            if (_self.model.areaSets[0].latitude && _self.model.areaSets[0].longitude) {
                _self.map.setZoom(_self.zoom);
                _self.map.setCenter({ lat: _self.model.areaSets[0].latitude, lng: _self.model.areaSets[0].longitude });
            }
        }
        
        @Watch('areaSetsVisible')
        private onAreaSetsVisibleChanged() {
            const _self = this;
            
            this.map.overlayMapTypes.clear();
            _self.model.areaSets.forEach(function (areaSet: any) {
                if (_self.areaSetsVisible != null &&  _self.areaSetsVisible.includes(areaSet.id)) {
                    _self.areasLayer = new google.maps.ImageMapType({
                        getTileUrl(coord: any, zoom: any) {
                            if (areaSet.mapTilesZoomLevelsAvailable >= zoom) {
                                const regex = /([^0-9a-zA-Z]+)/g;
                                const areaSetBlobName = areaSet.name.replace(regex, '-').toLowerCase();
                                const url = "https://tbdcirismaptiles.blob.core.windows.net/{a}/{z}/{x}_{y}.png".replace('{a}', areaSetBlobName).replace('{z}', zoom).replace('{x}', coord.x).replace('{y}', coord.y) + (areaSet.modified == null ? '' : "?" + areaSet.modified);
                                return url;
                            }
                            else {
                                return null;
                            }
                        },
                        tileSize: new google.maps.Size(256, 256),
                    });
                    _self.map.overlayMapTypes.insertAt(0, _self.areasLayer);
                }
            });


            
        }

        private findLocationById(id: any)
        {
            let foundLocation = null;
            this.locations.forEach(function (location: any) {
                if (location.id == id) 
                { 
                    foundLocation = location;
                    return;
                }
            });
            return foundLocation;
        }

        @Watch('location')
        private onLocationChanged(value: any, oldValue: any) {
            const _self = this;
            const oldLocation : any = oldValue == null ? null : this.findLocationById(oldValue.id);
            const newLocation : any = value == null ? null : this.findLocationById(value.id);
            const oldLocationMarker = oldLocation?.marker;
            const newLocationMarker = newLocation?.marker;
            if (oldLocationMarker)
            {
                oldLocationMarker.setIcon(_self.getIcon(oldLocation, false));
            }
            if (newLocationMarker)
            {
                newLocationMarker.setIcon(_self.getIcon(newLocation, true))
                if (value['zoomTo'] == true) 
                {
                    value['zoomTo'] = null;
                    _self.map.setZoom(15);
                }
                 if (_self.map.getBounds() != null && !_self.map.getBounds().contains(newLocationMarker.getPosition())) {
                     if (oldValue == null || value.id != oldValue.id) _self.map.setCenter(newLocationMarker.getPosition());
                }
            }
            
            if (this.circles != null) {
                this.circles.forEach(function (circle: any) {
                    circle.setMap(null);
                });
            }
            this.circles = [];
            if (value != null)
            {
                this.model.mapRanges.forEach(function (mapRange: any) {
                    _self.circles.push(new google.maps.Circle({
                        strokeColor: "#333333",
                        strokeOpacity: 1,
                        strokeWeight: 2,
                        zIndex: 4,
                        fillColor: "#AAAAAA",
                        fillOpacity: 0.1,
                        map: _self.map,
                        center: value,
                        radius: mapRange,
                        clickable: false
                    }));
                });
            }
            
        }
        private fromLatLngToPoint(latLng: any, map: any) {
            const topRight = map.getProjection().fromLatLngToPoint(map.getBounds().getNorthEast());
            const bottomLeft = map.getProjection().fromLatLngToPoint(map.getBounds().getSouthWest());
            const scale = Math.pow(2, map.getZoom());
            const worldPoint = map.getProjection().fromLatLngToPoint(latLng);
            return new google.maps.Point((worldPoint.x - bottomLeft.x) * scale, (worldPoint.y - topRight.y) * scale);
        }
        @Watch('searchResults')
        private onSearchResultsChanged(value: any) {
            const _self = this;
            if (_self.markersSearchResults) {
                _self.markersSearchResults.forEach(function (markerSearchResult: any) {
                    markerSearchResult.setMap(null);
                });
            }
            let longitudeTotal = 0;
            let latitudeTotal = 0;
            let found = 0;
            _self.markersSearchResults = value.map(function (poi: any) {
                try {
                    found++;
                    longitudeTotal += poi.longitude;
                    latitudeTotal += poi.latitude;
                    const marker = new google.maps.Marker({
                        zIndex: _self.ZINDEXSEARCHRESULT,
                        position: new google.maps.LatLng(poi.latitude, poi.longitude),
                        title: poi.title,
                        map: _self.map,
                        cursor: 'hand'
                    });
                    return marker;
                }
                catch (err) {
                    const marker = new google.maps.Marker({
                        zIndex: _self.ZINDEXSEARCHRESULT,
                        position: new google.maps.LatLng(poi.latitude, poi.longitude),
                        title: poi.title + ' (' + poi.category + ')',
                        cursor: 'hand'
                    });
                    return marker;
                }
            });
            if (found > 0) {
                _self.map.setCenter({ lat: latitudeTotal / found, lng: longitudeTotal / found });
            }
            if (_self.markersSearchResults.length == 1) {
                _self.map.setZoom(16);
            }
            
        }

        private addPoisToMap() {
            const _self = this;
            if (this.markerClustererPois) this.markerClustererPois.clearMarkers();

            // Create an empty array
            let allPois: any[] = [];

            // Check if _self.pois is not null and add the items to the array
            if (_self.pois !== null) {
                allPois = allPois.concat(_self.pois);
            }

            // Check if _self.onlinePois is not null and add the items to the array
            if (_self.onlinePois !== null) {
                allPois = allPois.concat(_self.onlinePois);
            }

            this.markersPois = allPois.map(function (poi: any) {
                let icon =
                {
                    url: '/api/models/' + _self.model.id + '/poisets/logo',
                    origin: new google.maps.Point(0, 0), // origin
                    scaledSize: new google.maps.Size(24, 24), // scaled size
                    anchor: new google.maps.Point(12, 12)
                };
                if (poi.poiSetId != null) {
                    icon = {
                        url: '/api/models/' + _self.model.id + '/poisets/' + poi.poiSetId + '/logo?r=' + poi.logoHash,
                        origin: new google.maps.Point(0, 0), // origin
                        scaledSize: new google.maps.Size(24, 24), // scaled size
                        anchor: new google.maps.Point(12, 12)
                    };
                }

                const marker = new google.maps.Marker({
                    zIndex: _self.ZINDEXPOI,
                    position: poi,
                    title: poi.name,
                    location: poi,
                    icon: icon,
                    cursor: 'hand'
                });
                return marker;
            });
            
            
            this.markerClustererPois.setMaxZoom(this.clusterPoiMaxZoom);
            this.markerClustererPois.setGridSize(this.clusterGridSize);
            this.markerClustererPois.addMarkers(_self.markersPois);
        }

        @Watch('onlinePois')
        @Watch('pois')
        private onOnlinePoisChanged() {
            this.calculateClusterMaxZoom();
            this.addPoisToMap();
        }

        private heatMap : any;

        @Watch('heatMapPoints')
        private onHeatMapPointsChanged(value: any) {
            const _self = this;
            _self.heatmaps.forEach(async function (heatMap: any) {
                heatMap.data.clear();
            });
            _self.heatmaps = [];
            let index = 0;
            value.forEach(async function (heatMapSetPoints: any) {
                const heatMapData = new google.maps.MVCArray(heatMapSetPoints.map(function (heatmap: any) {
                    return { location: new google.maps.LatLng(heatmap.latitude, heatmap.longitude), weight: heatmap.weight };
                }));
                const heatMap = new google.maps.visualization.HeatmapLayer({
                    data: heatMapData
                });
                _self.heatMapRadius = _self.model.heatMapSets[index].radius;
                if (_self.model.heatMapSets[index].radiusInMeters) {
                    heatMap.set("radius", _self.getHeatmapRadius());
                }
                else {
                    heatMap.set("radius", _self.model.heatMapSets[index].radius);
                }
                heatMap.set("dissipating", _self.model.heatMapSets[index].dissipating);
                heatMap.set("opacity", _self.model.heatMapSets[index].opacity);
                heatMap.set("maxIntensity", _self.model.heatMapSets[index].maxIntensity);
                heatMap.setMap(_self.map);
                _self.heatmaps.push(heatMap);
                
                index++;
            });
        }
        
        
        private calculateClusterMaxZoom()
        {
            const _self = this;
            _self.locationsCount = this.locations == null ? 0 : this.locations.length;
            _self.poiCount = this.pois == null ? 0 : this.pois.length;
            _self.scenarioLocationsCount = this.scenarioLocations == null?0:this.scenarioLocations.length;

            if (this.locationsCount >= 5000) this.clusterMaxZoom = 9;
            if (this.locationsCount < 5000) this.clusterMaxZoom = 8;
            if (this.locationsCount < 2000) this.clusterMaxZoom = 7;
            if (this.locationsCount < 1000) this.clusterMaxZoom = 6;
        }
        
        @Watch('locations')
        private onLocationsChanged(value: any) {
            const _self = this;
            this.calculateClusterMaxZoom();
            if (this.omsLocations) this.omsLocations.removeAllMarkers();
            if (this.markerClustererLocations) this.markerClustererLocations.clearMarkers();
            let firstChange = true;
            this.markersLocations = value.filter(function (location: any) { return location.visibility != 2 }).map(function (location: any) {
                const marker = new google.maps.Marker({
                    position: location,
                    location: location,
                    icon: _self.getIcon(location, location == _self.location),
                    title: location.name,
                    zIndex: _self.ZINDEXLOCATION + (location.type == 0?1:0)
                });
                marker.addListener('click', function (event: any) {
                    const point = _self.fromLatLngToPoint(event.latLng, _self.map);
                    _self.$emit('locationLeftClick', { x: point.x, y: point.y, latitude: event.latLng.lat(), longitude: event.latLng.lng(), location });
                });
                marker.addListener('rightclick', function (event: any) {
                    const point = _self.fromLatLngToPoint(event.latLng, _self.map);
                    _self.$emit('locationRightClick', { x: point.x, y: point.y, latitude: event.latLng.lat(), longitude: event.latLng.lng(), location });
                });
                _self.omsLocations.addMarker(marker);
                location['marker'] = marker;
                if (firstChange == true && location.changeType > 0) {
                    _self.map.setCenter(marker.position);
                    firstChange = false;
                }
                return marker;   
            });
          
            this.markerClustererLocations.setMaxZoom(this.clusterMaxZoom);
            this.markerClustererLocations.setGridSize(this.clusterGridSize);
            this.markerClustererLocations.addMarkers(_self.markersLocations);

            
            //this.onFilterChanged();
        }

        
      
        @Watch('scenarioLocationsVisible')
        @Watch('scenarioLocations')
        private onScenarioLocationsChanged() {
            const _self = this;
            this.calculateClusterMaxZoom();
            if (this.omsScenarioLocations) this.omsScenarioLocations.removeAllMarkers();
            if (this.markerClustererScenarioLocations) this.markerClustererScenarioLocations.clearMarkers();
            if (this.scenarioLocationsVisible) {
                this.markersScenarioLocations = _self.scenarioLocations.map(function (scenarioLocation: any) {
                     const marker = new google.maps.Marker({
                         position: scenarioLocation,
                         title: scenarioLocation.scenarioName + ' - ' + scenarioLocation.locationName,
                         scenarioLocation: scenarioLocation,
                         icon: _self.getIcon(scenarioLocation, false, true),
                         zIndex: _self.ZINDEXSCENARIOLOCATION
                     });
                     marker.addListener('click', function () {
                         marker.setIcon(_self.getIcon(scenarioLocation, true, true));
                         _self.$emit('scenarioLocationLeftClick', { scenarioLocation: scenarioLocation });
                     });
                     _self.omsLocations.addMarker(marker);
                     return marker;
                });

                this.markerClustererScenarioLocations.setMaxZoom(this.clusterMaxZoom);
                this.markerClustererScenarioLocations.setGridSize(this.clusterGridSize);
                this.markerClustererScenarioLocations.addMarkers(_self.markersScenarioLocations);

            }
            //this.onFilterChanged();
        }

        @Watch('filter')
        private onFilterChanged() {
            const _self = this;
            _self.$emit('startBusy');
            setTimeout(function () {
                if (_self.omsLocations) _self.omsLocations.forgetAllMarkers();
                let longitudeTotal = 0;
                let latitudeTotal = 0;
                let found = 0;
                _self.markersLocations.forEach(function (marker: any) {

                    const filter = _self.filter.toLowerCase();

                    if (localeIncludes(marker.location.name, filter, { usage: "search", sensitivity: "base" }) || (marker.location.locationGroup != null && localeIncludes(marker.location.locationGroup, filter, { usage: "search", sensitivity: "base" }))) {
                        if (_self.omsLocations) _self.omsLocations.trackMarker(marker);
                        marker.setVisible(true);
                        found++;
                        longitudeTotal += marker.position.lng();
                        latitudeTotal += marker.position.lat();
                    }
                    else {
                        marker.setVisible(false);
                        if (marker.location == _self.location) _self.$emit('deselect');
                    }
                });
                if (found > 0 && _self.filter != null && _self.filter != '') {
                    _self.map.setCenter({ lat: latitudeTotal / found, lng: longitudeTotal / found });


                }
                _self.$emit('endBusy');
            }, 0);
        }

        private getIcon(location: any, selected: boolean = false, isScenario: boolean = false) {
            let overlay1 = '';
            if (selected) overlay1 = '/Selected';
           
            
            let overlay2 = '';
            if (location.changeType == 3) {
                overlay2 = '/Edit';
            }
            if (location.changeType == 2) {
                overlay2 = '/Closing';
            }
            if (location.changeType == 1) {
                overlay2 = '/Opening';
            }
            
            let round = '';
            if (location.type == 1 || location.type == 4 || location.type == 5) {
                if (isScenario)
                {
                    overlay1 = '/ScenarioRound';
                    round = 'round';
                }
                else
                {
                    round = 'round';
                    
                }
            }
            else
            {
                if (isScenario) overlay1 = '/Scenario';

            }
            const icon = {
                url: '/api/models/location-groups/' + (location.locationGroupId === null ? -1 : location.locationGroupId) + '/logo/' + round + overlay1 + overlay2 + '?r=' + this.model.modified,
                scaledSize: new google.maps.Size(40, 58),
                origin: new google.maps.Point(0,0),
                anchor: new google.maps.Point(20, 56)
            };
            if (location.type == 1) {
                icon.scaledSize = new google.maps.Size(20, 20);
                icon.origin = new google.maps.Point(0, 0);
                icon.anchor = new google.maps.Point(10, 10);
            } else if (location.type == 4) {
                icon.scaledSize = new google.maps.Size(30, 30);
                icon.origin = new google.maps.Point(0, 0);
                icon.anchor = new google.maps.Point(15, 15);
            } else if (location.type == 5) {
                icon.scaledSize = new google.maps.Size(40, 40);
                icon.origin = new google.maps.Point(0, 0);
                icon.anchor = new google.maps.Point(20, 20);
            }
            return icon
        }
        @Watch('locationIsoLines')
        @Watch('isoLineSet')
        @Watch('isoLineMinutes')
        private onSelectedLocationIsoLines() {
            const _self = this;
            if (this.locationIsoLinesFeatures != null) {
                this.locationIsoLinesFeatures.forEach(function (feature: any) {
                    _self.map.data.remove(feature);
                });
            }
            
            if (this.locationIsoLines) {
                const features = this.locationIsoLines.features.filter(function (isoLine: any) {
                    return isoLine.properties.minutes == null || (isoLine.properties.minutes == _self.isoLineMinutes && isoLine.properties.isoLineSet == _self.isoLineSet.name);
                });
                this.locationIsoLinesFeatures = _self.map.data.addGeoJson({ "type": "FeatureCollection", "features": features });
            }
            else {
                this.locationIsoLinesFeatures = null
            }
        }
        @Watch('catchmentArea')
        @Watch('locationCatchmentArea')
        private onLocationCatchmentAreaChanged() {
            this.legend = [];

            const _self = this;

            if (this.locationCatchmentAreaFeatures != null) {
                this.locationCatchmentAreaFeatures.forEach(function (feature: any) {
                    _self.map.data.remove(feature);
                });
            }
            if (this.catchmentArea == null || this.locationCatchmentArea == null || _self.locationCatchmentArea.featureCollection == null) {
                this.locationCatchmentAreaFeatures = null;
            } else {
                const locationCatchmentAreaFeatures: any[] = [];
                let maximumNegativeDelta = 0;
                let maximumPositiveDelta = 0;
                let maximumDelta = 0;
                
                if (this.catchmentArea.impact == true) {
                    _self.locationCatchmentArea.featureCollection.features.forEach(function (feature: any) {
                        const valueDelta = feature.properties.value - feature.properties.valueDefault;
                        if (valueDelta < 0) {
                            if (maximumNegativeDelta < -valueDelta) maximumNegativeDelta = -valueDelta;
                        }
                        else {
                            if (maximumPositiveDelta < valueDelta) maximumPositiveDelta = valueDelta;
                        }
                    });
                    _self.locationCatchmentArea.featureCollection.features.forEach(function (feature: any) {
                        if (feature.properties.value != feature.properties.valueDefault) {
                            feature.properties.color = '#ffffff'
                            const valueDelta = feature.properties.value - feature.properties.valueDefault;
                            let fractionDiff;
                            // alert(maximumDelta + ' / Math.abs(' + expenseDelta + ')')
                            if (valueDelta < 0) {
                                fractionDiff = 1 / (maximumNegativeDelta / Math.abs(valueDelta));
                                feature.properties.color = _self.pickHex([200, 0, 0], [255, 240, 240], fractionDiff);
                                feature.properties['fillOpacity'] = 0.8;
                            }
                            else {
                                fractionDiff = 1 / (maximumPositiveDelta / Math.abs(valueDelta));
                                feature.properties.color = _self.pickHex([0, 50, 0], [50, 255, 50], fractionDiff);
                                feature.properties['fillOpacity'] = 0.5;
                            }
                            if (fractionDiff > 0.05) locationCatchmentAreaFeatures.push(feature);
                        }
                    });
                }
                else {
                    this.locationCatchmentArea.featureCollection.features.forEach(function (feature: any) {
                        if (maximumDelta < Math.abs(feature.properties.fraction)) maximumDelta = Math.abs(feature.properties.fraction)
                    });
                    let maximumFractionDiff = 0;
                    this.locationCatchmentArea.featureCollection.features.forEach(function (feature: any) {
                        const share = feature.properties.valueSharePrevious;
                        if (share <= _self.catchmentArea.coverage) {
                            let fractionDiff = 1 / (maximumDelta / Math.abs(feature.properties.fraction))
                            if (fractionDiff > 1) fractionDiff = 1.0;
                            if (fractionDiff > maximumFractionDiff) maximumFractionDiff = fractionDiff;
                            //if (fractionDiff < 0.3) fractionDiff = 0.3;
                            if (feature.properties.catchmentAreaZoneColor != null) {
                                feature.properties.color = feature.properties.catchmentAreaZoneColor;
                                // feature.properties['fillOpacity'] = 1.5;
                                //if (feature.properties.fraction < 1) feature.properties['fillOpacity'] = 0.5;
                                //if (feature.properties.fraction == 0) feature.properties['fillOpacity'] = 0.1;
                            }
                            else {
                                feature.properties.color = _self.pickHex([0, 96, 127], [75, 225, 255], fractionDiff);
                                // feature.properties['fillOpacity'] = 0.7
                            }
                            feature.properties['tooltip'] = null;
                            if (feature.properties.locationPropertyId == null) {
                                feature.properties['tooltip'] = feature.properties['areaCode'] + '<br/><br/>' + _self.$t('message.fraction') + ' : ' + Math.round(feature.properties['fraction'] * 100) / 100;
                            }
                            else {
                                let title = null;
                                let nonShared = '';
                                let shared = '';
                                let total = 0;
                                _self.locationCatchmentArea.locationProperties.forEach(function (locationProperty: any) {
                                    if (feature.properties.locationPropertyId == locationProperty.id) {
                                        if (feature.properties['tooltip'] == null) title = '<h3>' + locationProperty.title + '</h3>';
                                        if (locationProperty.fraction == 1) nonShared += '<div class="text-body-1">' + _self.$t('message.nonShared') + ' : ' + locationProperty.output + '</div>';
                                        if (locationProperty.fraction < 1 && locationProperty.fraction > 0) shared += '<div class="text-body-1">' + _self.$t('message.sharedWith') + ' ' + ((1 / locationProperty.fraction) - 1) + ' ' + _self.$t('message.otherLocations') + ' : ' + Math.round(locationProperty.output) + ' (' + locationProperty.originalOutput + ')</div>';
                                        total += locationProperty.output;
                                        //if (locationProperty.fraction == 0) feature.properties['tooltip'] += locationProperty.originalOutput + ' (Excluded)' + '<br/>';
                                    }
                                });
                                feature.properties['tooltip'] = title + '<br/>' + '<div class="text-body-1">' + _self.$t('message.total') + ' : ' + Math.round(total) + '</div>'
                                if (nonShared != '') feature.properties['tooltip'] += nonShared;
                                if (shared != '') feature.properties['tooltip'] += shared;
                            }
                            locationCatchmentAreaFeatures.push(feature);
                        }
                    });
                    if (this.model.catchmentAreaZones.length > 0) {
                        this.model.catchmentAreaZones.forEach(function (catchmentAreaZone: any) {
                            _self.model.catchmentAreas.forEach(function (catchmentArea: any) {
                                if (catchmentArea.name == catchmentAreaZone.catchmentAreaName && catchmentArea.id == _self.catchmentArea.catchmentAreaId) {
                                    _self.legend.push(catchmentAreaZone);
                                }
                            });
                        })
                    } else {
                        _self.legend.push({ title: '100%', color: _self.pickHex([0, 96, 127], [75, 225, 255], maximumFractionDiff) });
                        _self.legend.push({ title: '80%', color: _self.pickHex([0, 96, 127], [75, 225, 255], maximumFractionDiff * 0.8) });
                        _self.legend.push({ title: '60%', color: _self.pickHex([0, 96, 127], [75, 225, 255], maximumFractionDiff * 0.6) });
                        _self.legend.push({ title: '40%', color: _self.pickHex([0, 96, 127], [75, 225, 255], maximumFractionDiff * 0.4) });
                        _self.legend.push({ title: '20%', color: _self.pickHex([0, 96, 127], [75, 225, 255], maximumFractionDiff * 0.2) });
                    }
                }
                this.locationCatchmentAreaFeatures = this.map.data.addGeoJson({ "type": "FeatureCollection", "features": locationCatchmentAreaFeatures })
            }
            this.$emit('legendChanged', this.legend);
        }
        private pickHex(color1: any, color2: any, weight: any) {
            const w1 = weight;
            const w2 = 1 - w1;
            const rgb = [Math.round(color1[0] * w1 + color2[0] * w2),
            Math.round(color1[1] * w1 + color2[1] * w2),
            Math.round(color1[2] * w1 + color2[2] * w2)];
            const h = rgb[0] * 0x10000 + rgb[1] * 0x100 + rgb[2] * 0x1;
            return '#' + ('000000' + h.toString(16)).slice(-6);
		}
       
        @Watch('area')
        private onAreaChanged() {
            const _self = this;
            if (_self.areaLines == null) _self.areaLines = [];
            //remove lines from map
            _self.areaLines.forEach(function (line: any) {
                line.setMap(null);
            })
            //remove features from map
            if (this.areaFeatures != null) {
                this.areaFeatures.forEach(function (feature: any) {
                    _self.map.data.remove(feature);
                });
            }
            if (_self.area != null) {
                _self.areaFeatures = _self.map.data.addGeoJson({ "type": "FeatureCollection", "features": _self.area.featureCollection });
                if (_self.area.locations != null) {
                    _self.area.locations.forEach(function (arrow: any) {
                        const line = new google.maps.Polyline({
                            path: [{ lng: arrow.areaPoint.longitude, lat: arrow.areaPoint.latitude }, { lng: arrow.locationPoint.longitude, lat: arrow.locationPoint.latitude }],
                            strokeWeight: 1.5,
                            strokeOpacity: 0.5,
                            zIndex: 4,
                            map: _self.map
                        });
                        const cityCircle = new google.maps.Circle({
                            center: arrow.locationPoint,
                            strokeOpacity: 1,
                            strokeColor: 'black',
                            fillColor: '#97a5de',
                            fillOpacity: 0.8,
                            zIndex: 4,
                            map: _self.map,
                            radius: (1 / (_self.area.totalValue / arrow.value)) * 300
                        });
                        _self.areaLines.push(cityCircle);
                        _self.areaLines.push(line);
                    });
                }
            }
        }
    }
