import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { MainState } from './types';
import { RootState } from '../types';

export const state: MainState = {
    filterLocations: '',
    standByFilterLocations: '',
    filterScenarios: '',
    standByFilterScenarios: '',
    scenarioLocationsVisible: false,
    searchResults: [],
    mapBounds: null,
    locationPhoto: null,
    models: [],
    model: null,
    subModels: [],
    subModel: null,
    areaSetsVisible: [],
    poiSetsVisible: [],
    heatMapSetsVisible: [],
    scenarios: [],
    scenarioLocations: [],
    scenario: null,
    drivers: [],
    scenarioDifferences: null,
    locations: [],
    locationsNotExcluded: [],
    onlinePois: [],
    propertiesChart: null,
    matchingLocations: [],
    matchingScores: [],
    locationDrivers: null,
    location: null,
    locationProperties: null,
    locationPropertiesOld: null,
    locationScores: null,
  
    locationIsoLines: null,
    locationCatchmentArea: null,
    
    area: null,
    modelParameters: null,
    scenarioProcessingLog: null,
    normalizedScoresFunctions: null,
    locationEditing: null,
    scenarioEditing: null,
    scenarioMerging: null,
    fetchCatchmentAreaBusy: null,
    pois: [],
    heatMapPoints: [],
    readOnly: false,
    isoLinesFetchingBusy: null,
    log: null,
    isoLineParameters: null,
    matchingColors: ['#009CBB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#008FFB'],
    isoLineSet: null,
    isoLineMinutes: 0,
    catchmentArea: null,
};

const namespaced = true;

export const main: Module<MainState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
